<template>
  <v-card tile flat :data-step="dataStep" :data-intro="dataIntro">
    <v-card-title class="font-weight-light align-right text-center">
      {{ title }}
    </v-card-title>
    <v-card-text
      class="align-right text-center display-3 accent--text"
      :style="`height: ${height}px`"
    >
      <slot />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button :to="footerTo || '/billing'" v-if="footerMessage">
        {{ footerMessage }}
      </default-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  components: { DefaultButton },
  props: [
    "title",
    "display",
    "footerMessage",
    "footerTo",
    "height",
    "dataIntro",
    "dataStep",
  ],
};
</script>
