<template>
  <v-col cols="12">
    <div>
      <h1
        class="display-1 font-weight-light"
        style="font-family: Avenir !important"
        :data-step="dataStep"
        :data-intro="dataIntro"
      >
        <slot>
          {{ $route.name }}
        </slot>
      </h1>
      <slot name="subtitle"></slot>
    </div>
  </v-col>
</template>

<script>
export default {
  props: ["dataStep", "dataIntro"],
};
</script>
