<template>
  <v-row class="ma-4">
    <page-header />
    <loader v-if="$apollo.queries.dashboard.loading" />
    <v-row v-else>
      <v-col cols="12" md="6" lg="3" id="dashboard-step1">
        <stats-card title="Total packages created">
          {{ totalPackageCount }}
        </stats-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <stats-card title="Python packages">
          {{ dashboard.pythonPackageCount }}
        </stats-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <stats-card title="NPM packages">
          {{ dashboard.npmPackageCount }}
        </stats-card>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <stats-card title="Docker registries">
          {{ dashboard.registryCount }}
        </stats-card>
      </v-col>

      <v-col cols="12" md="6">
        <stats-card
          id="dashboard-step2"
          title="Storage Usage"
          footer-message="Upgrade to increase your limits"
          :height="300"
        >
          <v-progress-circular
            :size="300"
            :width="15"
            :value="storageValue"
            color="success"
          >
            <div class="small">
              {{ dashboard.totalStorageUsed | fileSize }} /
              {{ dashboard.storageAllowance | fileSize }}
              <br />({{ storageValue }} %)
            </div>
          </v-progress-circular>
        </stats-card>
      </v-col>

      <v-col cols="12" md="6">
        <stats-card
          id="dashboard-step3"
          title="Latest activity"
          footer-message="See all"
          footer-to="/logs"
          :height="300"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Action</th>
                  <th class="text-left">Time</th>
                  <th class="text-left">User</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dashboard.lastTenLogs" :key="item.id">
                  <td class="text-left">{{ item.action }}</td>
                  <td class="text-left">{{ item.created | niceDate }}</td>
                  <td class="text-left">
                    <span v-if="item.user">{{ item.user.username }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </stats-card>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { queries } from "@/gql";
import { dashboardSteps as steps, defaultOptions as options } from "@/tour";
import { fileSize, niceDate } from "@/filters";
const { dashboard } = queries;

import PageHeader from "@/components/widgets/PageHeader";
import Loader from "@/components/base/Loader";
import StatsCard from "@/components/base/StatsCard";
export default {
  name: "Home",
  filters: { fileSize, niceDate },
  apollo: { dashboard },
  components: { StatsCard, Loader, PageHeader },
  watch: {
    dashboard(dashboard) {
      if (dashboard) {
        if (dashboard.newAccount) {
          const self = this;
          setTimeout(() => {
            const intro = self.$intro();
            intro.setOptions({ steps, ...options });
            intro.start();
          }, 500);
        }
      }
    },
  },
  computed: {
    storageValue() {
      if (this.dashboard) {
        const { totalStorageUsed, storageAllowance } = this.dashboard;
        return parseInt((totalStorageUsed / storageAllowance) * 100);
      }
      return 0;
    },
    totalPackageCount() {
      if (this.dashboard) {
        const { pythonPackageCount, npmPackageCount, registryCount } =
          this.dashboard;
        return pythonPackageCount + npmPackageCount + registryCount;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.small {
  font-size: 36px !important;
}
</style>
