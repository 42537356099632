export const dashboardSteps = [
  {
    title: "Welcome to Packagr",
    intro:
      "Welcome to Packagr! Let's take a quick tour of the dashboard to get started<br /><br />" +
      "You're currently looking at the dashboard. It shows you a brief summary of your Packagr account.",
  },
  {
    element: "#dashboard-step1",
    title: "Total packages created",
    intro:
      "This card shows the total number of packages you have created of all types. Packagr currently " +
      "supports Python and NPM packages, plus Docker images. The three other similar cards here show your " +
      "counts for all three types of package",
  },
  {
    element: "#dashboard-step2",
    title: "Storage Usage",
    intro: "This card shows you have much of the allocated space you have used",
  },
  {
    element: "#dashboard-step3",
    title: "Latest activity",
    intro:
      "Packagr tracks all interactions with your private repository. The most recent actions are displayed here",
  },
  {
    element: "#dashboard-step4",
    intro:
      "You can view and manage the packages you have created by visiting this section",
  },
  {
    element: "#dashboard-step5",
    intro:
      "This is your Python repository - lets take a closer look by clicking here...",
  },
];

export const pythonSteps = [
  {
    title: "Python Package list",
    intro: "All Python packages you have access to are listed in this view",
  },
  {
    element: "#python-step1",
    title: "Repository URL",
    intro:
      "This is your unique Python repository URL. You can use it to upload packages using Twine",
  },
  {
    element: "#python-step2",
    intro:
      "Clicking on this button will display the exact commands to upload packages to your Python repository",
  },
  {
    element: "#python-dummy-package-name",
    intro:
      'For now, we\'ve created this dummy package for you. You can click on the "Edit" button to view more ' +
      "details about it, or to change or delete it.",
  },
];
export const defaultOptions = {
  exitOnOverlayClick: false,
  dontShowAgain: true,
  dontShowAgainCookie: "dontShow",
  disableInteraction: false,
};

export const dummyPackageSteps = [
  {
    title: "Python Package view",
    intro:
      "This view shows a list of all versions of a specific Python package",
  },
  {
    element: "#dummypackage-install",
    intro:
      "This button will let you copy the pip install command allowing you to install this package on your local " +
      "machine",
  },
  {
    element: "#dummypackage-download",
    intro:
      "Alternatively, you can use this button to download the package directly.",
  },
  {
    element: "#dummypackage-security",
    intro:
      "Packagr automatically scans all newly published Python package for known security vulnerabilities. " +
      "You can click on this button to see them listed",
  },
  {
    element: "#dummypackage-permissions",
    intro:
      "Packagr provides fine grained access controls for all package types. Package access can either be " +
      "managed at an account level (the default behaviour), or at a package level. Use this button to switch " +
      "between the two options. You can read more about setting package permissions in the " +
      '<a href="https://docs.packagr.app/help/managing-access/#package-registry-level-access">docs</a>',
  },
  {
    element: "#dummypackage-delete",
    intro:
      "You can click on this button to at any time to delete this specific version of the package. If this is" +
      "only version available, the whole package will be deleted, as well as the version . Note that this " +
      "action cannot be undone.",
  },
];

export default {
  dashboardSteps,
  pythonSteps,
  defaultOptions,
  dummyPackageSteps,
};
